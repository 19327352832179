import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// sections
import Content from './sections/Content';
import { PageProps } from 'gatsby';

const CookiePolicy: FC<PageProps> = () => {
  return (
    <PageLayout>
      <Intro
        title='Cookie Policy'
        description='This Cookie Policy contains detailed information about how and when we use cookies on our website, as well as the options you have to control them.'
        icon='cookiePolicyDrawing'
      />
      <Content />
    </PageLayout>
  );
};

export default CookiePolicy;
