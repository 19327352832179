// components
import Seo from 'src/components/Seo';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={`Cookie Policy - Seedium`}
      description={`'Learn more about how we collect and use cookies, as well as the options you have to control them.'`}
      schema={{
        schemaContext: 'https://schema.org',
        schemaType: 'WebPage',
        schemaName: 'Cookie Policy - Seedium',
        schemaDescription:
          'Learn more about how we collect and use cookies, as well as the options you have to control them.',
        schemaUrl: 'https://seedium.io',
        schemaLogo: 'https://seedium.io/images/logo.png',
      }}
    />
  );
};

export { default } from './CookiePolicy';
